import React from 'react';
import SEO from '../components/App/SEO';
import Layout from '../components/App/Layout';
import Navbar from "../components/App/Navbar";
import Footer from "../components/App/Footer";
import { graphql } from 'gatsby';

// Components
import DropDownButton from '../components/App/DropDownButton'; // Update path if needed

// Images
import iPhoneImg from '../assets/images/blue-chat/iphone.webp';
import iphoneReversedImg from '../assets/images/blue-chat/iphoneReversed.webp';
import powerFullImg from '../assets/images/blue-chat/powerfull.webp';
import secureImg from '../assets/images/blue-chat/secure.webp';
import crossPlatformImg from '../assets/images/blue-chat/cross-platf.webp';
import messagingImg from '../assets/images/blue-chat/messages.webp';
import teamsImg from '../assets/images/blue-chat/teams.webp';
import indicatorsImg from '../assets/images/blue-chat/indicators.webp';

const BlueChat = ({ data }) => {
  return (
    <Layout env={data.site.siteMetadata.env}>
      <Navbar />
      <div className="blue-chat-container bg-nav">
        <SEO
          postTitle="Download BlueMail & Experience Built-In Chat"
          postDescription="Chat seamlessly within BlueMail"
          postImage="/img/bluechat/iOS-2.png"
          postURL="bluechat"
          postSEO
        />
        
        <div className="container content">
          {/* Hero Section */}
          <div className="row">
            <div className="col bkg">
              <div className="row">
                <div className="col-lg-9 col-md-9 col-xs-12 d-flex align-items-center">
                  <div className="row">
                    <div className="col ml-5">
                      <h1>
                        Chat in BlueMail <br />
                        built for your <span>productivity</span>
                      </h1>
                      <h4 className="mt-3" style={{ color: "#B0B0B0", fontWeight: "400" }}>
                        Stay in sync with coworkers, classmates, or personal contacts 
                        by using your existing email address for fast, secure 1:1 or group conversations. 
                        Our patented technology ensures a smooth experience. No extra apps required.
                      </h4>

                      {/* DropDownButton replaces store icons */}
                      <div className="mt-5">
                        <DropDownButton />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-xs-12 phone">
                  <img src={iPhoneImg} alt="Preview of BlueMail Chat on iPhone" />
                </div>
              </div>
            </div>
          </div>

          {/* Core Features */}
          <div className="row mt-5">
            <div className="col-lg-6 col-md-6 col-xs-12 p--5">
              <h2>
                <span>Free,</span> Fast and Integrated
              </h2>
            </div>
          </div>

          <div className="row mb-5">
            <div className="col p--5">
              <div className="row">
                {/* Powerful */}
                <div className="col-lg-4 col-md-4 col-xs-12 mt-3">
                  <img className="icon" src={powerFullImg} alt="Powerful" />
                  <h5 className="mt-3 mb-3">Powerful</h5>
                  <p style={{ lineHeight: "28px" }}>
                    Collaborate with teams, share files and manage group chats effortlessly. 
                    Our technology delivers lightning-fast performance 
                    across all your devices.
                  </p>
                </div>

                {/* Secure */}
                <div className="col-lg-4 col-md-4 col-xs-12 mt-3">
                  <img className="icon" src={secureImg} alt="Secure" />
                  <h5 className="mt-3 mb-3">Secure</h5>
                  <p style={{ lineHeight: "28px" }}>
                    Feel confident with end-to-end encryption (E2EE) and robust security measures. 
                    Your conversations remain under your full control safe from prying eyes.
                  </p>
                </div>

                {/* Cross-Platform */}
                <div className="col-lg-4 col-md-4 col-xs-12 mt-3">
                  <img className="icon" src={crossPlatformImg} alt="Cross-Platform" />
                  <h5 className="mt-3 mb-3">Cross-Platform</h5>
                  <p style={{ lineHeight: "28px" }}>
                    Chat anytime, anywhere, from mobile to desktop. 
                    Stay productive no matter which device or operating system you use.
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* Privacy Section */}
          <div className="row">
            <div className="col bkg">
              <div className="row">
                <div className="col-lg-4 col-md-4 col-xs-12 phoneLeft">
                  <img src={iphoneReversedImg} alt="Phone showcasing chat interface" />
                </div>
                <div className="col-lg-8 offset-lg-4 col-md-8 offset-md-4 col-xs-12 d-flex align-items-center">
                  <div className="row">
                    <div className="col ml-5">
                      <h2>
                        We take your <span>privacy</span> seriously. <br />
                        Your messages never leave your control.
                      </h2>
                      <p style={{ lineHeight: "28px", marginTop: "1rem", color: "#B0B0B0" }}>
                        With BlueMail’s data handling approach, your messages are securely stored and encrypted. 
                        We never share your data with third parties. Period.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Real-Time Communication */}
          <div className="row mt-5">
            <div className="col-lg-6 col-md-6 col-xs-12 p--5">
              <p style={{ color: "#B0B0B0", margin: "0" }}>EFFICIENT</p>
              <h2>
                <span>Real-Time</span> Communication
              </h2>
            </div>
          </div>
          <div className="row">
            <div className="col p--5">
              <div className="row">
                {/* 1:1 Messaging */}
                <div className="col-lg-4 col-md-4 col-xs-12 mt-3">
                  <img className="icon" src={messagingImg} alt="1:1 Messaging" />
                  <h5 className="mt-3 mb-3">1:1 Messaging</h5>
                  <p style={{ lineHeight: "28px" }}>
                    Connect directly with friends, family or coworkers using your existing email addresses. 
                    No need for new usernames or sign-ups just open BlueMail and chat.
                  </p>
                </div>

                {/* Teams */}
                <div className="col-lg-4 col-md-4 col-xs-12 mt-3">
                  <img className="icon" src={teamsImg} alt="Teams" />
                  <h5 className="mt-3 mb-3">Teams</h5>
                  <p style={{ lineHeight: "28px" }}>
                    Quickly spin up group chats for projects, clubs or study groups. 
                    Keep everyone updated instantly and coordinate tasks more efficiently.
                  </p>
                </div>

                {/* Indicators */}
                <div className="col-lg-4 col-md-4 col-xs-12 mt-3">
                  <img className="icon" src={indicatorsImg} alt="Indicators" />
                  <h5 className="mt-3 mb-3">Indicators</h5>
                  <p style={{ lineHeight: "28px" }}>
                    Check message delivery and read receipts at a glance. 
                    Stay informed about the status of critical messages and follow up as needed.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>  
      <Footer />
    </Layout>
  );
};

export default BlueChat;

export const query = graphql`
  query BlueChatPageQuery {
    site {
      siteMetadata {
        title
        env
      }
    }
  }
`;
